import * as React from "react";
import { observer } from "mobx-react";
import { UitkCard, UitkCardContentSection, UitkCardLink } from "@egds/react-core/cards";
import { UitkImage, UitkFigure, UitkFigureAspectRatioType } from "@egds/react-core/images";
import { UitkSpacing } from "@egds/react-core/spacing";
import { Action } from "components/utility/analytics/FlexAnalyticsUtils";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { destinationOfferItem } from "../../Common/MerchCampaignCard/MerchCampaignCard";
import { OfferItem } from "../../types";
import { UitkHeading, UitkParagraph } from "@egds/react-core/text";

export interface VRCardProps {
  offer: OfferItem;
  productType: string;
  cardIndex: number;
  campaignId: string;
  displayPropertyCount?: boolean;
  moduleName: string;
}

export const VRCard = observer(
  ({ offer, productType, cardIndex, campaignId, moduleName, displayPropertyCount }: VRCardProps) => {
    if (offer.__typename !== "DestinationOfferItem") {
      return null;
    }

    const offerItem = destinationOfferItem(productType, offer);

    if (offerItem === null || offerItem === undefined) {
      return null;
    }

    return (
      <>
        <UitkCard className="VRCard">
          <UitkCardContentSection padded={false}>
            <UitkFigure ratio={UitkFigureAspectRatioType.R16_9} className="uitk-card-roundcorner-all">
              <UitkImage src={offer.image.url} alt={offer.image.description} />
            </UitkFigure>
            <UitkCardLink>
              <TrackedLink
                moduleName={moduleName}
                action={Action.CLICK}
                href={offerItem?.actionLink.href}
                rfrr={`card-${campaignId}-${cardIndex}`}
              >
                {offer.name}
              </TrackedLink>
            </UitkCardLink>
          </UitkCardContentSection>
          <UitkSpacing padding={{ blockstart: "one" }}>
            <div className="uitk-card-content">
              <UitkHeading tag="h3" size={6}>
                {offer.name}
              </UitkHeading>
              {displayPropertyCount && (offer as any).propertyCount && (
                <UitkParagraph>{(offer as any).propertyCount}</UitkParagraph>
              )}
            </div>
          </UitkSpacing>
        </UitkCard>
      </>
    );
  }
);

import * as React from "react";
import { useDialog } from "@shared-ui/dialog-context";
import { observer } from "mobx-react";
import { UitkCard, UitkCardLink, UitkCardContentSection } from "@egds/react-core/cards";
import { locKeys } from "components/shared/Merchandising/Inventory/l10n";
import { useLocalization } from "@shared-ui/localization-context";
import { withStores } from "stores";
import { InfoDialog } from "../../Common/InfoDialog/InfoDialog";
import { UitkLayoutPosition } from "@egds/react-core/layout-position";
import { UitkSpacing, UitkSpacingHr } from "@egds/react-core/spacing";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { Action } from "components/utility/analytics/FlexAnalyticsUtils";
import { UitkFigure, UitkFigureAspectRatioType, UitkImage } from "@egds/react-core/images";
import { UitkBadge, UitkDealMemberBadge, UitkVipBadge } from "@egds/react-core/badge";
import { UitkIcon, UitkIconSize } from "@egds/react-core/icons";
import { UitkMark, UitkMarkSize } from "@egds/react-core/mark";
import { UitkLayoutGrid } from "@egds/react-core/layout-grid";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";
import { UitkHeading, UitkText } from "@egds/react-core/text";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkSheetTransition } from "@egds/react-core/sheet";
import { PriceLockup } from "components/shared/PriceLockup/PriceLockup";
import { OfferItem } from "../../types";

interface MarkBrandMapType {
  expedia: string;
  lastminute: string | undefined;
  orbitz: string;
  travelocity: string;
  wotif: string;
  cheaptickets: string;
  ebookers: string;
}

const MarkBrandMap: MarkBrandMapType = {
  expedia: "mod_exp",
  lastminute: undefined,
  orbitz: "mod_orb",
  travelocity: "mod_tvly",
  wotif: "mod_wotif",
  cheaptickets: "mod_ctx",
  ebookers: "mod_ebook",
};

const defaultUndefinedMark = "lastminute";

export interface HotelInventoryCardProps {
  offer: OfferItem;
  cardIndex: number;
  campaignId: string;
  moduleName: string;
  border?: boolean;
  context?: ExtendedContextStore;
}

export const HotelInventoryCard = ({ offer, cardIndex, campaignId, moduleName, border }: HotelInventoryCardProps) => {
  if (offer.__typename !== "HotelOfferItem") {
    return null;
  }

  return (
    <UitkCard className="HotelInventoryCard" border={border} data-testid="HotelInventoryCard">
      <UitkFigure ratio={UitkFigureAspectRatioType.R21_9}>
        <UitkSpacing margin={{ blockstart: "three" }}>
          <UitkLayoutPosition type="absolute" position={{ left: "three", zindex: "layer2" }} cloneElement>
            <UitkLayoutFlex space="two">
              <ImageBadges offer={offer} />
            </UitkLayoutFlex>
          </UitkLayoutPosition>
        </UitkSpacing>
        <UitkImage src={offer.image.url} alt={offer.image.description} placeholderImage lazyLoading="lazy" />
      </UitkFigure>
      <UitkCardContentSection className="HotelInventoryCardSection">
        <UitkSpacing padding={{ blockend: "four" }}>
          <UitkLayoutPosition type="relative">
            <div>
              <UitkSpacing padding={{ blockend: "half" }}>
                <UitkHeading overflow="truncate" tag="h3" size={5}>
                  {offer.name}
                </UitkHeading>
              </UitkSpacing>
              <UitkText size={300}>{offer.location}</UitkText>
              {offer.guestReviewRating && (
                <UitkLayoutPosition type="absolute" position={{ bottom: "minus-two" }} cloneElement>
                  <div className="reviewRating">
                    <UitkLayoutFlex>
                      <UitkSpacing padding={{ inlineend: "one" }}>
                        <UitkText weight="bold" size={300}>
                          {offer.guestReviewRating}
                        </UitkText>
                      </UitkSpacing>
                      <UitkText size={300} className="pwa-theme--grey-700" overflow="truncate">
                        {offer.guestReviewCount}
                      </UitkText>
                    </UitkLayoutFlex>
                  </div>
                </UitkLayoutPosition>
              )}
            </div>
          </UitkLayoutPosition>
        </UitkSpacing>
      </UitkCardContentSection>
      <UitkLayoutPosition type="relative">
        <UitkCardContentSection className="HotelInventoryCardSection">
          <Separator offer={offer} />
          {offer.freeCancellationMessage && (
            <UitkLayoutPosition type="absolute" position={{ top: "six" }} cloneElement>
              <UitkText size={300} weight="bold" theme="positive" spacing="two">
                {offer?.freeCancellationMessage}
              </UitkText>
            </UitkLayoutPosition>
          )}
          <UitkLayoutGrid alignItems="end" columns={2} space="two">
            <>
              <UitkText align="left" size={200}>
                {offer?.dates}
              </UitkText>
              <UitkText align="right">
                <PriceComponent offer={offer} cardIndex={cardIndex} campaignId={campaignId} moduleName={moduleName} />
              </UitkText>
            </>
          </UitkLayoutGrid>
        </UitkCardContentSection>
      </UitkLayoutPosition>
      <UitkCardLink>
        {offer.lodgingOffer && (
          <TrackedLink
            moduleName={moduleName}
            action={Action.CLICK}
            href={offer.lodgingOffer?.actionLink.href}
            rfrr={`card-${campaignId}-${cardIndex}`}
          >
            {offer.name}
          </TrackedLink>
        )}
      </UitkCardLink>
    </UitkCard>
  );
};

interface MarkIconProps {
  context: ExtendedContextStore;
}

type OfferProps = Pick<HotelInventoryCardProps, "offer">;

const MarkIcon = withStores("context")(
  observer(({ context }: MarkIconProps) => {
    const markName = MarkBrandMap[(context.site?.brand as keyof MarkBrandMapType) ?? defaultUndefinedMark];
    if (markName) {
      return <UitkMark name={markName} size={UitkMarkSize.SMALL} />;
    }
    return <UitkIcon name="mod" size={UitkIconSize.SMALL} />;
  })
);

const ImageBadges: React.FC<OfferProps> = ({ offer }) => {
  if (offer.__typename !== "HotelOfferItem") {
    return null;
  }

  if (offer?.vipMessage && offer?.mod?.modTagText) {
    return (
      <>
        <UitkLayoutFlexItem>
          <UitkDealMemberBadge>
            <MarkIcon />
          </UitkDealMemberBadge>
        </UitkLayoutFlexItem>
        <UitkLayoutFlexItem>
          <UitkVipBadge text={offer.vipMessage} />
        </UitkLayoutFlexItem>
      </>
    );
  }

  if (offer?.vipMessage) {
    return <UitkVipBadge text={offer.vipMessage} />;
  }

  if (offer?.mod?.modTagText) {
    return (
      <UitkLayoutFlexItem>
        <UitkDealMemberBadge text={offer.mod.modTagText}>
          <MarkIcon />
        </UitkDealMemberBadge>
      </UitkLayoutFlexItem>
    );
  }

  return null;
};

const SeperatorBadge = ({ modText, badgeText }: { modText?: string | null; badgeText?: string }) => {
  if (modText) {
    return <UitkDealMemberBadge text={badgeText} />;
  }
  return <UitkBadge text={badgeText} />;
};

const Separator: React.FC<OfferProps> = ({ offer }) => {
  if (offer.__typename !== "HotelOfferItem") {
    return null;
  }

  const badgeText = offer.discountBadge?.text;
  const badgeClass = badgeText ? "separatorLineWidth" : "";

  if (offer?.mod?.modAvailabilityText) {
    return (
      <>
        <div className={badgeClass}>
          <UitkSpacingHr blockMargin="one" />
        </div>
        <UitkLayoutPosition type="relative" position={{ top: "minus-four" }} cloneElement>
          <UitkText align="right">
            <UitkDealMemberBadge text={offer?.mod?.modAvailabilityText} />
          </UitkText>
        </UitkLayoutPosition>
      </>
    );
  }
  if (badgeText) {
    return (
      <>
        <div className={badgeClass}>
          <UitkSpacingHr blockMargin="one" />
        </div>
        <UitkLayoutPosition type="relative" position={{ top: "minus-four" }} cloneElement>
          <UitkText align="right">
            <SeperatorBadge modText={offer.mod?.modTagText} badgeText={badgeText} />
          </UitkText>
        </UitkLayoutPosition>
      </>
    );
  }
  return (
    <UitkSpacing padding={{ blockend: "six" }}>
      <div>
        <UitkSpacingHr blockMargin="one" />
      </div>
    </UitkSpacing>
  );
};

interface PriceComponentProps {
  offer: OfferItem;
  cardIndex: number;
  campaignId: string;
  moduleName: string;
  cardClicked?: () => void;
}

const PriceComponent = observer(({ offer, cardIndex, campaignId, moduleName, cardClicked }: PriceComponentProps) => {
  const disclaimerRef = React.useRef<HTMLButtonElement>(null);

  if (offer.__typename !== "HotelOfferItem") {
    return null;
  }

  const { formatText } = useLocalization();
  const [isDialogOpen, dialogActions, PriceDialogComponent] = useDialog(`disclaimer-info-${campaignId}-${cardIndex}`);

  if (offer?.mod?.modSignInText) {
    return (
      <TrackedLink
        moduleName={moduleName}
        action={Action.CLICK}
        rfrr={`card-${campaignId}-${cardIndex}-MOD-SignUp`}
        onClick={cardClicked}
        href={offer?.mod?.signInUrl?.relativePath}
      >
        <UitkText size={300} weight="bold" align="right">
          {offer?.mod?.modSignInText}
        </UitkText>
      </TrackedLink>
    );
  }

  const closeDialog = () => {
    dialogActions.closeDialog();

    /* istanbul ignore next */
    if (disclaimerRef?.current) {
      disclaimerRef.current.focus();
    }
  };

  return (
    <>
      <PriceLockup
        lockupPrice={offer.lodgingOffer?.price?.value}
        priceSubtextFirst={offer.lodgingOffer?.price?.qualifierText}
        a11yPrice={offer.lodgingOffer?.price?.allyText || ""}
        a11yStrikeThroughDialogTrigger={formatText(locKeys.priceDisclaimerLabel)}
        strikeThroughPrice={offer?.lodgingOffer?.strikeThroughPrice?.value}
        hasMoreInfoTrigger
        onClickMoreInfoTrigger={dialogActions.openDialog}
        disclaimerRef={disclaimerRef}
      />
      <UitkSheetTransition isVisible={isDialogOpen}>
        <PriceDialogComponent>
          <InfoDialog
            infoText={offer?.lodgingOffer?.price.disclaimerText}
            isDialogOpen={isDialogOpen}
            closeDialog={closeDialog}
            buttonText={formatText("DestinationsByAffinity.priceDialogCloseButton")}
          />
        </PriceDialogComponent>
      </UitkSheetTransition>
    </>
  );
});

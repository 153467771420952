import * as React from "react";
import { observer } from "mobx-react";
import { useDialog } from "@shared-ui/dialog-context";

import { useLocalization } from "@shared-ui/localization-context";

import { UitkLink } from "@egds/react-core/link";
import { UitkHeading, UitkHeadingProps, UitkHeadingSize } from "@egds/react-core/text";
import { UitkSpacing } from "@egds/react-core/spacing";

import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";

import { InfoDialog } from "../InfoDialog/InfoDialog";
import { CampaignVR } from "../CampaignVR/CampaignVR";
import { CampaignDescription } from "../CampaignDescription/CampaignDescription";
import { UitkSheetTransition } from "@egds/react-core/sheet";
import { CampaignDetail } from "../../types";

export interface CampaignHeaderProps {
  campaign: CampaignDetail;
  campaignRefs: number;
  campaignTitle?: string;
  campaignCopyAlignment?: string;
  showCampaignDescription?: boolean;
  showCampaignTitle?: boolean;
  showCampaignTermsAndConditions?: boolean;
  view?: string;
  imgSrc?: string;
  seeMoreLinkUrl?: string;
  context?: ExtendedContextStore;
  campaignTitleSize?: UitkHeadingSize;
}

// header of the campaigns with title, subtitle and terms and conditions
export const CampaignHeader = observer(
  ({
    campaign,
    campaignRefs,
    campaignTitle,
    campaignCopyAlignment,
    showCampaignDescription,
    showCampaignTitle,
    showCampaignTermsAndConditions,
    view,
    imgSrc,
    seeMoreLinkUrl,
    context,
    campaignTitleSize,
  }: CampaignHeaderProps) => {
    const { formatText } = useLocalization();
    const [isDialogOpen, dialogActions, HeaderDialogComponent] = useDialog(`terms-conditions-${campaign.campaignId}`);
    const isHavingOriginCity = campaign.fromOriginHeaderText;
    const defaultHeadingConfig: UitkHeadingProps = { tag: "h2", size: campaignTitleSize || 3 };
    const centeredHeadingConfig: UitkHeadingProps = { tag: "h2", size: 2 };
    const campaignHeading = campaignTitle || campaign.headline;
    const isCentered = campaignCopyAlignment === "centered";
    const headingConfig = isCentered ? centeredHeadingConfig : defaultHeadingConfig;
    const showDescription = showCampaignDescription !== undefined ? showCampaignDescription : true;
    const showTitle = showCampaignTitle !== undefined ? showCampaignTitle : true;
    const showTermsAndConditions = showCampaignTermsAndConditions !== undefined ? showCampaignTermsAndConditions : true;
    const image = imgSrc || "";
    const seeMoreURL = seeMoreLinkUrl || "";

    if (!showDescription && !showTitle && !showTermsAndConditions) {
      return null;
    }

    if (view === "carousel-vr" && image !== "") {
      return (
        <CampaignVR
          campaign={campaign}
          campaignTitle={campaignTitle}
          context={context}
          imgSrc={imgSrc}
          seeMoreLinkUrl={seeMoreURL}
        />
      );
    }

    return (
      <UitkSpacing padding={{ blockstart: isCentered ? "three" : "unset" }}>
        <div
          className={isCentered ? "center-campaignCopyAlignment" : "CampaignHeader"}
          data-testid={`campaign-header-${campaignRefs}`}
        >
          {showTitle && (
            <UitkHeading tabIndex={-1} id={`campaign-header-${campaignRefs}`} {...headingConfig}>
              {campaignHeading}
            </UitkHeading>
          )}
          <UitkSpacing padding={{ blockstart: isCentered ? "one" : "unset" }}>
            <div>
              <CampaignDescription
                campaign={campaign}
                isCentered={isCentered}
                isHavingOriginCity={isHavingOriginCity}
                showDescription={showDescription}
              />
              {campaign.termsAndConditions && showTermsAndConditions && (
                <>
                  <UitkLink
                    className={isCentered ? "center_termsAndConditions" : "terms-conditions-text"}
                    inline={!isCentered}
                    onClick={dialogActions.openDialog as any}
                    align={isCentered ? "center" : "left"}
                  >
                    <button type="button">{formatText("merchCampaign.termsConditions.label")}</button>
                  </UitkLink>
                  <UitkSheetTransition isVisible={isDialogOpen}>
                    <HeaderDialogComponent>
                      <InfoDialog
                        infoText={campaign.termsAndConditions}
                        isDialogOpen={isDialogOpen}
                        closeDialog={dialogActions.closeDialog}
                        buttonText={formatText("DestinationsByAffinity.priceDialogCloseButton")}
                      />
                    </HeaderDialogComponent>
                  </UitkSheetTransition>
                </>
              )}
            </div>
          </UitkSpacing>
        </div>
      </UitkSpacing>
    );
  }
);

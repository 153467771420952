import * as React from "react";
import { UitkDialog, UitkDialogContent, UitkDialogFooter } from "@egds/react-core/dialog";
import { UitkParagraph } from "@egds/react-core/text";
import { UitkToolbar } from "@egds/react-core/toolbar";
import { useLocalization } from "@shared-ui/localization-context";

interface InfoDialogProps {
  buttonText: string;
  closeDialog: () => void;
  infoText?: string;
  isDialogOpen?: boolean;
  /* show or hide toolbar */
  hideToolbar?: boolean;
}

export const InfoDialog: React.FC<InfoDialogProps> = ({
  infoText,
  closeDialog,
  buttonText,
  isDialogOpen,
  hideToolbar,
}) => {
  const { formatText } = useLocalization();

  return (
    <UitkDialog dialogShow={isDialogOpen} returnFocusOnClose={true}>
      {!hideToolbar ? (
        <UitkToolbar
          navigationContent={{
            navIconLabel: formatText("DestinationsByAffinity.priceDialogCloseButton"),
            onClick: closeDialog,
          }}
        />
      ) : null}
      <UitkDialogContent>
        {infoText?.split("\n").map((value, index) => (
          <UitkParagraph key={index}>{value}</UitkParagraph>
        ))}
        <UitkDialogFooter
          buttonOne={{
            onClick: closeDialog,
            text: buttonText,
            type: "tertiary",
          }}
        />
      </UitkDialogContent>
    </UitkDialog>
  );
};

import * as React from "react";
import { observer } from "mobx-react";
import { UitkCard, UitkCardLink, UitkCardContentSection } from "@egds/react-core/cards";
import { Action } from "components/utility/analytics/FlexAnalyticsUtils";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { useLocalization } from "@shared-ui/localization-context";
import { OfferItem } from "../../types";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkText } from "@egds/react-core/text";

export interface FlightInventoryCardProps {
  offer: OfferItem;
  cardIndex: number;
  campaignId: string;
  moduleName: string;
  border?: boolean;
}

export const FlightInventoryCard = observer(
  ({ offer, cardIndex, campaignId, moduleName, border }: FlightInventoryCardProps) => {
    if (offer.__typename !== "FlightOfferItem") {
      return null;
    }

    const { formatText } = useLocalization();

    return (
      <UitkCard className="FlightInventoryCard" border={border}>
        <div className="uitk-card-content">
          <UitkCardContentSection>
            <div>
              <UitkSpacing margin={{ inlineend: "one" }}>
                <img className="airlinesIcon" src={offer.airlineImage?.url} alt={offer.airlineImage?.description} />
              </UitkSpacing>
              <UitkText inline size={200}>
                {offer.airlineName}
              </UitkText>
            </div>
            <UitkSpacing padding={{ blockend: "one", inlineend: "one" }}>
              <UitkText size={400} overflow="truncate">
                <div
                  className="flightOfferName"
                  dangerouslySetInnerHTML={{
                    __html: formatText(
                      "merchCampaign.flightOffer.name",
                      offer.originCity,
                      offer.originAirportCode,
                      offer.destinationCity,
                      offer.destinationAirportCode
                    ),
                  }}
                />
              </UitkText>
            </UitkSpacing>
            <UitkText size={300}>{offer.description}</UitkText>
          </UitkCardContentSection>
          <UitkCardContentSection border="top">
            <UitkLayoutFlex justifyContent="space-between">
              <>
                <UitkText size={200}>{offer.dates}</UitkText>
                {offer.flightOffer?.price.value && (
                  <div>
                    <UitkSpacing padding={{ inlineend: "one" }}>
                      <UitkText size={100} inline align="right" className="price-prefix">
                        {offer.flightOffer.price.prefixText}
                      </UitkText>
                    </UitkSpacing>
                    <UitkText inline size={600} weight="medium" align="right" theme="emphasis">
                      {offer.flightOffer.price.value}
                    </UitkText>
                    <UitkText size={100} align="right">
                      {offer.flightOffer.price.qualifierText}
                    </UitkText>
                  </div>
                )}
              </>
            </UitkLayoutFlex>
          </UitkCardContentSection>
          <UitkCardLink>
            <TrackedLink
              moduleName={moduleName}
              action={Action.CLICK}
              href={offer.flightOffer?.actionLink.href}
              rfrr={`card-${campaignId}-${cardIndex}`}
            >
              {offer.flightOffer?.actionLink.text}
            </TrackedLink>
          </UitkCardLink>
        </div>
      </UitkCard>
    );
  }
);

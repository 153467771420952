import * as React from "react";
import { observer } from "mobx-react";
import { UitkCard, UitkCardLink } from "@egds/react-core/cards";
import { UitkHeading, UitkText } from "@egds/react-core/text";
import { UitkFigure, UitkFigureAspectRatioType, UitkImage } from "@egds/react-core/images";
import { UitkScrim, UitkScrimType } from "@egds/react-core/scrim";
import { UitkLink } from "@egds/react-core/link";
import { Action } from "components/utility/analytics/FlexAnalyticsUtils";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { DestinationOfferPriceInfo } from "../DestinationOfferPriceInfo/DestinationOfferPriceInfo";
import { destinationOfferItem } from "../../Common/MerchCampaignCard/MerchCampaignCard";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { OfferItem } from "../../types";

export interface FullBleedCardProps {
  offer: OfferItem;
  productType: string;
  cardIndex: number;
  campaignId: string;
  displayDate: boolean;
  displayPrice: boolean;
  moduleName: string;
  border?: boolean;
}

export const FullBleedCard = observer(
  ({
    offer,
    productType,
    cardIndex,
    campaignId,
    displayDate,
    displayPrice,
    moduleName,
    border,
  }: FullBleedCardProps) => {
    if (offer.__typename !== "DestinationOfferItem") {
      return null;
    }

    const offerItem = destinationOfferItem(productType, offer);

    if (offerItem === null || offerItem === undefined) {
      return null;
    }

    return (
      <>
        <UitkCard className="FullBleedCard" border={border}>
          <UitkFigure ratio={UitkFigureAspectRatioType.R3_2}>
            <UitkImage src={offer.image.url} alt={offer.image.description} placeholderImage lazyLoading="lazy" />
            <UitkScrim type={UitkScrimType.BOTTOM}>
              <UitkLayoutFlex justifyContent="space-between">
                <>
                  <UitkLayoutFlexItem>
                    <div>
                      <UitkHeading tag="h3" size={5}>
                        {offer.name}
                      </UitkHeading>
                      {displayDate && <UitkText size={300}>{offer.dates}</UitkText>}
                      <UitkText size={300}>{offer.secondaryContent}</UitkText>
                    </div>
                  </UitkLayoutFlexItem>
                  <UitkLayoutFlexItem>
                    <div>
                      {displayPrice && offerItem.price.value && (
                        <DestinationOfferPriceInfo
                          scrimDisplay
                          offerPrice={offerItem.price}
                          cardIndex={cardIndex}
                          campaignId={campaignId}
                        />
                      )}
                      {displayPrice && offerItem.price.checkPriceText && (
                        <div className="uitk-scrim">
                          <UitkLink align="right">
                            <UitkText weight="bold">
                              <a className="cardContent" href={offerItem.actionLink.href}>
                                {offerItem.price.checkPriceText}
                              </a>
                            </UitkText>
                          </UitkLink>
                        </div>
                      )}
                    </div>
                  </UitkLayoutFlexItem>
                </>
              </UitkLayoutFlex>
            </UitkScrim>
          </UitkFigure>
          <UitkCardLink>
            <TrackedLink
              moduleName={moduleName}
              action={Action.CLICK}
              href={offerItem.actionLink.href}
              rfrr={`card-${campaignId}-${cardIndex}`}
            >
              {offerItem.actionLink.text}
            </TrackedLink>
          </UitkCardLink>
        </UitkCard>
      </>
    );
  }
);

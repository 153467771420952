import * as React from "react";

import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkText } from "@egds/react-core/text";

import { CampaignDetail } from "../../types";

export interface CampaignDescriptionProps {
  campaign: CampaignDetail;
  isCentered: boolean;
  isHavingOriginCity?: string | null;
  showDescription: boolean;
}

export const CampaignDescription: React.FC<CampaignDescriptionProps> = ({
  campaign,
  isCentered,
  isHavingOriginCity,
  showDescription,
}) => {
  if (!showDescription) {
    return null;
  }

  const { description, fromOriginHeaderText } = campaign;
  const textSize = isCentered ? 400 : 300;
  const marginSpacing = isCentered ? "unset" : "one";
  const isInlineText = !isCentered;

  return (
    <>
      {description.long && (
        <UitkSpacing margin={{ inlineend: "one" }}>
          <UitkText inline={isInlineText} size={textSize}>
            {description.long}
          </UitkText>
        </UitkSpacing>
      )}
      {isHavingOriginCity && (
        <UitkSpacing margin={{ inlineend: marginSpacing }}>
          <UitkText inline={isInlineText} size={textSize}>{`${fromOriginHeaderText}.`}</UitkText>
        </UitkSpacing>
      )}
    </>
  );
};

import * as React from "react";
import { useDialog } from "@shared-ui/dialog-context";
import { observer } from "mobx-react";
import { OfferPrice } from "__generated__/typedefs";
import { UitkIcon, UitkIconSize } from "@egds/react-core/icons";
import { InfoDialog } from "../../Common/InfoDialog/InfoDialog";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkText } from "@egds/react-core/text";
import { UitkSheetTransition } from "@egds/react-core/sheet";

export const DestinationOfferPriceInfo = observer(
  ({
    scrimDisplay,
    offerPrice,
    cardIndex,
    campaignId,
  }: {
    scrimDisplay: boolean;
    offerPrice: OfferPrice;
    cardIndex: number;
    campaignId: string;
  }) => {
    const { formatText } = useLocalization();
    const [isDialogOpen, dialogActions, DestinationOfferDialogComponent] = useDialog(
      `disclaimer-info-${campaignId}-${cardIndex}`
    );

    return (
      <div className="DestinationOfferPriceInfo">
        {offerPrice.prefixText && (
          <UitkText
            size={scrimDisplay ? 200 : 100}
            weight={scrimDisplay ? "regular" : undefined}
            className={scrimDisplay ? "cardContent" : ""}
          >
            {offerPrice.prefixText}
          </UitkText>
        )}
        {offerPrice.disclaimerText && (
          <button className="disclaimerInfoIcon" onClick={dialogActions.openDialog as any}>
            <UitkIcon
              name="info_outline"
              size={UitkIconSize.SMALL}
              id={`disclaimer-info-${campaignId}-${cardIndex}`}
              title={formatText("merchCampaign.disclaimerIcon.title")}
            />
          </button>
        )}
        <UitkSpacing margin={{ inlinestart: "one" }}>
          <UitkText
            inline
            theme="emphasis"
            weight="medium"
            size={scrimDisplay ? 500 : 600}
            className={scrimDisplay ? "cardContent" : ""}
          >
            {offerPrice.value}
          </UitkText>
        </UitkSpacing>
        <UitkSpacing margin={{ inlinestart: "one" }}>
          <UitkText
            size={scrimDisplay ? 200 : 100}
            weight={scrimDisplay ? "regular" : undefined}
            className={scrimDisplay ? "cardContent" : ""}
          >
            {offerPrice.qualifierText}
          </UitkText>
        </UitkSpacing>
        {offerPrice.disclaimerText && (
          <UitkSheetTransition isVisible={isDialogOpen}>
            <DestinationOfferDialogComponent>
              <InfoDialog
                infoText={offerPrice.disclaimerText}
                isDialogOpen={isDialogOpen}
                closeDialog={dialogActions.closeDialog}
                buttonText={formatText("DestinationsByAffinity.priceDialogCloseButton")}
              />
            </DestinationOfferDialogComponent>
          </UitkSheetTransition>
        )}
      </div>
    );
  }
);

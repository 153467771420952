import * as React from "react";
import { observer } from "mobx-react";
import { UitkCard, UitkCardLink, UitkCardContentSection } from "@egds/react-core/cards";
import { UitkFigure, UitkFigureAspectRatioType, UitkImage } from "@egds/react-core/images";
import { Action } from "components/utility/analytics/FlexAnalyticsUtils";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { DestinationOfferPriceInfo } from "../DestinationOfferPriceInfo/DestinationOfferPriceInfo";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { destinationOfferItem } from "../../Common/MerchCampaignCard/MerchCampaignCard";
import { OfferItem } from "../../types";
import { UitkHeading, UitkText } from "@egds/react-core/text";
import { UitkLink } from "@egds/react-core/link";

export interface ImageTopCardProps {
  offer: OfferItem;
  productType: string;
  cardIndex: number;
  campaignId: string;
  displayDate: boolean;
  displayDescription: boolean;
  displayPrice: boolean;
  moduleName: string;
  border?: boolean;
}

export const ImageTopCard = observer(
  ({
    offer,
    productType,
    cardIndex,
    campaignId,
    displayDate,
    displayDescription,
    displayPrice,
    moduleName,
    border,
  }: ImageTopCardProps) => {
    if (offer.__typename !== "DestinationOfferItem") {
      return null;
    }

    const offerItem = destinationOfferItem(productType, offer);

    if (offerItem === null || offerItem === undefined) {
      return null;
    }

    return (
      <>
        <UitkCard border={border}>
          <UitkFigure ratio={UitkFigureAspectRatioType.R21_9}>
            <UitkImage src={offer.image.url} alt={offer.image.description} placeholderImage lazyLoading="lazy" />
          </UitkFigure>
          <UitkCardLink>
            <TrackedLink
              moduleName={moduleName}
              action={Action.CLICK}
              href={offerItem.actionLink.href}
              rfrr={`card-${campaignId}-${cardIndex}`}
            >
              {offerItem.actionLink.text}
            </TrackedLink>
          </UitkCardLink>
          <div className="uitk-card-content">
            <UitkCardContentSection>
              <UitkLayoutFlex justifyContent="space-between">
                <>
                  <UitkLayoutFlexItem>
                    <div>
                      <UitkHeading tag="h3" size={5}>
                        {offer.name}
                      </UitkHeading>
                      {displayDate && (
                        <UitkText size={300} weight="regular">
                          {offer.dates}
                        </UitkText>
                      )}
                      <UitkText size={300} weight="regular">
                        {offer.secondaryContent}
                      </UitkText>
                    </div>
                  </UitkLayoutFlexItem>
                  <UitkLayoutFlexItem>
                    <UitkText align="right">
                      {displayPrice && offerItem?.price.value && (
                        <DestinationOfferPriceInfo
                          scrimDisplay={false}
                          offerPrice={offerItem.price}
                          cardIndex={cardIndex}
                          campaignId={campaignId}
                        />
                      )}
                      {displayPrice && offerItem.price.checkPriceText && (
                        <UitkLink>
                          <UitkText weight="bold">
                            <a href={offerItem.actionLink.href}>{offerItem.price.checkPriceText}</a>
                          </UitkText>
                        </UitkLink>
                      )}
                    </UitkText>
                  </UitkLayoutFlexItem>
                </>
              </UitkLayoutFlex>
            </UitkCardContentSection>
            {displayDescription && offer.description && (
              <UitkCardContentSection border="top">
                {offer.description && (
                  <UitkText size={300} weight="regular">
                    {offer.description}
                  </UitkText>
                )}
              </UitkCardContentSection>
            )}
          </div>
        </UitkCard>
      </>
    );
  }
);

import * as React from "react";
import { observer } from "mobx-react";

import { Viewport, ViewSmall, ViewMedium, ViewLarge } from "@shared-ui/viewport-context";

import { UitkCard, UitkCardContentSection } from "@egds/react-core/cards";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import {
  UitkLayoutGrid,
  UitkLayoutGridItem,
  UitkLayoutConditionalGridTrack,
  UitkLayoutConditionalGridSpan,
} from "@egds/react-core/layout-grid";
import { UitkFigureAspectRatioType } from "@egds/react-core/images";
import { UitkScrimType } from "@egds/react-core/scrim";
import { UitkSpacing, UitkSpacingProps } from "@egds/react-core/spacing";
import { UitkText, UitkHeading } from "@egds/react-core/text";

import { ScrimCard } from "components/shared/ScrimCard/ScrimCard";
import { ExtendedContextStore } from "typings/flexFramework/FlexDefinitions";

import { useLocalization } from "@shared-ui/localization-context";
import { UitkLink } from "@egds/react-core/link";

import { CampaignDetail } from "../../types";

export interface CampaignVRProps {
  campaign: CampaignDetail;
  campaignTitle?: string;
  imgSrc?: string;
  context?: ExtendedContextStore;
  seeMoreLinkUrl?: string;
}

export const CampaignVR = observer(({ campaign, campaignTitle, imgSrc, seeMoreLinkUrl }: CampaignVRProps) => {
  const campaignHeading = campaignTitle || campaign.headline;
  const image = imgSrc || "";
  const seeMoreUrl = seeMoreLinkUrl || "";
  const locSeeMoreKey = "propertyfilter.seeMore";
  const { formatText } = useLocalization();

  const scrimCardPropsSmall = {
    id: "vr-campaign-header",
    dataTestId: "vr-campaign-header",
    className: "",
    titleSlot: (
      <UitkHeading tag="h3" size={3}>
        {campaignHeading}
      </UitkHeading>
    ),
    subtitleSlot: (
      <UitkLayoutFlex direction="column" justifyContent="space-between" style={{ height: "inherit" }}>
        <UitkSpacing padding={{ small: { blockstart: "unset" } }}>
          <UitkLayoutFlexItem>
            <div>
              <UitkText size={500} theme="inverse">
                {campaign.description.long}
              </UitkText>
            </div>
          </UitkLayoutFlexItem>
        </UitkSpacing>
      </UitkLayoutFlex>
    ),
  };

  const mediumClassName = "vrScrim";
  const mediumSpacing: UitkSpacingProps = {
    padding: { blockend: "unset" },
  };
  const titleGrid: UitkLayoutConditionalGridTrack = { medium: 12 };
  const titleColSpan: UitkLayoutConditionalGridSpan = { medium: 7, large: 3 };

  const scrimCardPropsMedium = {
    id: "vr-campaign-header",
    dataTestId: "vr-campaign-header",
    className: mediumClassName,
    titleSlot: (
      <UitkLayoutGrid columns={titleGrid} space="two">
        <UitkLayoutGridItem colSpan={titleColSpan}>
          <UitkSpacing padding={{ inlinestart: "six" }}>
            <div>
              <Viewport>
                <ViewMedium>
                  <UitkHeading tag="h3" size={3}>
                    {campaignHeading}
                  </UitkHeading>
                </ViewMedium>
                <ViewLarge>
                  <UitkHeading tag="h3" size={2}>
                    {campaignHeading}
                  </UitkHeading>
                </ViewLarge>
              </Viewport>
            </div>
          </UitkSpacing>
        </UitkLayoutGridItem>
      </UitkLayoutGrid>
    ),
    footerSlot: (
      <UitkCard className="vrFooter">
        <UitkCardContentSection>
          <UitkSpacing padding={{ inlinestart: "three", block: "three" }}>
            <UitkLayoutFlex>
              <UitkText size={300}>
                {campaign.description.long}
                {seeMoreUrl !== "" && (
                  <UitkLink inline>
                    <a href={seeMoreUrl} target="_blank" rel="noopener noreferrer">{` ${formatText(locSeeMoreKey)}`}</a>
                  </UitkLink>
                )}
              </UitkText>
            </UitkLayoutFlex>
          </UitkSpacing>
        </UitkCardContentSection>
      </UitkCard>
    ),
    spacing: mediumSpacing,
  };

  return (
    <Viewport>
      <ViewSmall>
        <ScrimCard
          {...scrimCardPropsSmall}
          aspectRatio={UitkFigureAspectRatioType.R1_1}
          backgroundImageURL={image}
          scrimType={UitkScrimType.OVERLAY}
        />
      </ViewSmall>
      <ViewMedium>
        <ScrimCard
          {...scrimCardPropsMedium}
          aspectRatio={UitkFigureAspectRatioType.R4_1}
          backgroundImageURL={image}
          scrimType={UitkScrimType.OVERLAY}
        />
      </ViewMedium>
    </Viewport>
  );
});

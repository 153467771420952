import * as React from "react";
import { useDialog } from "@shared-ui/dialog-context";
import { UitkCard, UitkCardContentSection, UitkCardLink } from "@egds/react-core/cards";
import { UitkLayoutGrid } from "@egds/react-core/layout-grid";
import { UitkLayoutFlex, UitkLayoutFlexItem } from "@egds/react-core/layout-flex";
import { UitkLayoutPosition } from "@egds/react-core/layout-position";
import { UitkIcon, UitkIconSize } from "@egds/react-core/icons";
import { locKeys } from "components/shared/Merchandising/Inventory/l10n";
import { useLocalization } from "@shared-ui/localization-context";
import { PriceLockup } from "components/shared/PriceLockup/PriceLockup";
import { InfoDialog } from "../../Common/InfoDialog/InfoDialog";
import { UitkSpacing, UitkSpacingHr } from "@egds/react-core/spacing";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { Action } from "components/utility/analytics/FlexAnalyticsUtils";
import { UitkFigure, UitkFigureAspectRatioType, UitkImage } from "@egds/react-core/images";
import { UitkBadge } from "@egds/react-core/badge";
import { UitkHeading, UitkText } from "@egds/react-core/text";
import { OfferItem } from "../../types";
import { UitkSheetTransition } from "@egds/react-core/sheet";

export interface ActivityInventoryCardProps {
  offer: OfferItem;
  cardIndex: number;
  campaignId: string;
  moduleName: string;
  border?: boolean;
}
export const ActivityInventoryCard = ({
  offer,
  cardIndex,
  campaignId,
  moduleName,
  border,
}: ActivityInventoryCardProps) => {
  const disclaimerRef = React.useRef<HTMLButtonElement>(null);
  const { formatText } = useLocalization();
  const [isDialogOpen, dialogActions, ActivityDialogComponent] = useDialog(
    `disclaimer-info-${campaignId}-${cardIndex}`
  );

  if (offer.__typename !== "ActivityOfferItem") {
    return null;
  }

  const strikeThroughPrice = offer?.activityOffer?.strikeThroughPrice?.value;
  const currentPrice = offer.activityOffer?.price?.value;
  const a11yPrice = offer.activityOffer?.price?.allyText || "";
  const priceDisclaimerLabel = formatText(locKeys.priceDisclaimerLabel);

  const closeDialog = () => {
    dialogActions.closeDialog();

    /* istanbul ignore next */
    if (disclaimerRef?.current) {
      disclaimerRef.current.focus();
    }
  };

  return (
    <UitkCard className="ActivityInventoryCard" border={border} overflow={false}>
      <UitkLayoutFlex direction="column" justifyContent="end">
        <UitkLayoutFlexItem shrink={0} grow={0}>
          <UitkFigure ratio={UitkFigureAspectRatioType.R21_9}>
            <UitkImage src={offer.image.url} alt={offer.image.description} placeholderImage lazyLoading="lazy" />
          </UitkFigure>
        </UitkLayoutFlexItem>
        <UitkLayoutFlexItem grow={1}>
          <UitkLayoutFlex direction="column" justifyContent="space-around">
            <UitkLayoutFlexItem shrink={0} grow={1} alignSelf="start">
              <UitkCardContentSection>
                <div>
                  <UitkSpacing padding={{ blockend: "half" }}>
                    <UitkHeading tag="h3" size={5}>
                      {offer.title}
                    </UitkHeading>
                  </UitkSpacing>
                  <UitkText size={300} inline>
                    {offer.city}
                  </UitkText>
                </div>
              </UitkCardContentSection>
            </UitkLayoutFlexItem>
            <UitkCardContentSection>
              <div>
                <DiscountBadge offer={offer} />
              </div>
              <UitkLayoutFlexItem grow={1} shrink={0}>
                <UitkLayoutGrid alignItems="end" columns={2} space="two">
                  <>
                    <div>
                      <FreeCancellation offer={offer} />
                      <UitkLayoutPosition type="relative">
                        <div>
                          <Duration offer={offer} />
                        </div>
                      </UitkLayoutPosition>
                    </div>
                    <UitkLayoutPosition position={{ zindex: "layer2" }}>
                      <PriceLockup
                        lockupPrice={currentPrice}
                        priceSubtextFirst={offer.activityOffer?.price?.qualifierText}
                        a11yPrice={a11yPrice}
                        a11yStrikeThroughDialogTrigger={priceDisclaimerLabel}
                        strikeThroughPrice={strikeThroughPrice}
                        hasMoreInfoTrigger
                        onClickMoreInfoTrigger={dialogActions.openDialog}
                        disclaimerRef={disclaimerRef}
                      />
                      <UitkSheetTransition isVisible={isDialogOpen}>
                        <ActivityDialogComponent>
                          <InfoDialog
                            infoText={offer?.activityOffer?.price.disclaimerText}
                            isDialogOpen={isDialogOpen}
                            closeDialog={closeDialog}
                            buttonText={formatText("DestinationsByAffinity.priceDialogCloseButton")}
                          />
                        </ActivityDialogComponent>
                      </UitkSheetTransition>
                    </UitkLayoutPosition>
                  </>
                </UitkLayoutGrid>
              </UitkLayoutFlexItem>
            </UitkCardContentSection>
            <UitkCardLink>
              {offer.activityOffer && (
                <TrackedLink
                  moduleName={moduleName}
                  action={Action.CLICK}
                  href={offer.activityOffer?.actionLink.href}
                  rfrr={`card-${campaignId}-${cardIndex}`}
                >
                  {offer.activityOffer?.actionLink.text}
                </TrackedLink>
              )}
            </UitkCardLink>
          </UitkLayoutFlex>
        </UitkLayoutFlexItem>
      </UitkLayoutFlex>
    </UitkCard>
  );
};

type OfferProps = Pick<ActivityInventoryCardProps, "offer">;

const FreeCancellation: React.FC<OfferProps> = ({ offer }) => {
  if (offer.__typename !== "ActivityOfferItem") {
    return null;
  }

  if (!offer.freeCancellationMessage) return null;

  return (
    <UitkSpacing padding={{ blockend: "two" }}>
      <UitkText theme="positive" size={300} weight="bold">
        {offer?.freeCancellationMessage}
      </UitkText>
    </UitkSpacing>
  );
};

const Duration: React.FC<OfferProps> = ({ offer }) => {
  if (offer.__typename !== "ActivityOfferItem") {
    return null;
  }

  if (offer?.duration) {
    return (
      <UitkLayoutFlexItem>
        <UitkLayoutFlex alignItems="center">
          <UitkSpacing margin={{ inlineend: "one" }}>
            <UitkText size={200} inline>
              {offer?.durationLabel}
            </UitkText>
          </UitkSpacing>
          <UitkSpacing margin={{ inlineend: "one" }}>
            <UitkIcon name="access_time" size={UitkIconSize.EXTRA_SMALL} id="access_time" />
          </UitkSpacing>
          <UitkText size={200} inline>
            {offer?.duration}
          </UitkText>
        </UitkLayoutFlex>
      </UitkLayoutFlexItem>
    );
  }
  return null;
};

const DiscountBadge: React.FC<OfferProps> = ({ offer }) => {
  if (offer.__typename !== "ActivityOfferItem") {
    return null;
  }

  const discountBadge = offer.badge?.text;

  if (!discountBadge) {
    return (
      <UitkSpacing padding={{ blockend: "six" }}>
        <div>
          <UitkSpacingHr blockMargin="unset" />
        </div>
      </UitkSpacing>
    );
  }

  return (
    <>
      <UitkSpacingHr blockMargin="unset" />
      <UitkLayoutPosition type="relative" position={{ top: "minus-three" }}>
        <UitkLayoutFlex justifyContent="end">
          <UitkLayoutFlexItem>
            <UitkBadge text={discountBadge} />
          </UitkLayoutFlexItem>
        </UitkLayoutFlex>
      </UitkLayoutPosition>
    </>
  );
};

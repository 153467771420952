import * as React from "react";
import { observer } from "mobx-react";
import { UitkCard, UitkCardLink, UitkCardContentSection } from "@egds/react-core/cards";
import { UitkFigure, UitkFigureAspectRatioType, UitkImage } from "@egds/react-core/images";
import { Action } from "components/utility/analytics/FlexAnalyticsUtils";
import { TrackedLink } from "components/utility/analytics/TrackedLink";
import { UitkLayoutFlex } from "@egds/react-core/layout-flex";
import { useLocalization } from "@shared-ui/localization-context";
import { UitkVipBadge } from "@egds/react-core/badge";
import { UitkSpacing } from "@egds/react-core/spacing";
import { UitkText, UitkHeading, UitkParagraph } from "@egds/react-core/text";
import { UitkLink } from "@egds/react-core/link";
import { OfferItem } from "../../types";

export interface PackageInventoryCardProps {
  offer: OfferItem;
  cardIndex: number;
  campaignId: string;
  moduleName: string;
  border?: boolean;
}

export const PackageInventoryCard = observer(
  ({ offer, cardIndex, campaignId, moduleName, border }: PackageInventoryCardProps) => {
    if (offer.__typename !== "PackageOfferItem") {
      return null;
    }

    const { hotelOfferItem, flightOfferItem, packageOffer } = offer;
    const { formatText } = useLocalization();

    return (
      <UitkCard className="PackageInventoryCard" border={border}>
        <UitkFigure ratio={UitkFigureAspectRatioType.R21_9}>
          {hotelOfferItem?.vipMessage && (
            <UitkSpacing margin={{ blockstart: "three", inlinestart: "three" }}>
              <UitkVipBadge className="hotelBadge" text={hotelOfferItem.vipMessage} />
            </UitkSpacing>
          )}
          <UitkImage src={offer.image.url} alt={offer.image.description} placeholderImage lazyLoading="lazy" />
        </UitkFigure>
        <div className="uitk-card-content">
          <UitkCardContentSection>
            <UitkParagraph size={2}>{offer.description}</UitkParagraph>
            <UitkSpacing padding={{ blockend: "half" }}>
              <UitkHeading tag="h3" size={5} overflow="truncate">
                {hotelOfferItem?.name}
              </UitkHeading>
            </UitkSpacing>
            <UitkText size={300}>{hotelOfferItem?.location}</UitkText>
            <UitkSpacing padding={{ blockstart: "three" }}>
              <div>
                <UitkSpacing margin={{ inlineend: "one" }}>
                  <img
                    className="airlinesIcon"
                    src={flightOfferItem?.airlineImage?.url}
                    alt={flightOfferItem?.airlineImage?.description}
                  />
                </UitkSpacing>
                <UitkText size={200} inline>
                  {flightOfferItem?.airlineName}
                </UitkText>
              </div>
            </UitkSpacing>
            {flightOfferItem && (
              <UitkSpacing padding={{ blockend: "one" }}>
                <UitkText size={300}>
                  {formatText(
                    "merchCampaign.packageOffer.flightOffer.name",
                    flightOfferItem.originCity,
                    flightOfferItem.originAirportCode,
                    flightOfferItem.destinationCity,
                    flightOfferItem.destinationAirportCode
                  )}
                </UitkText>
              </UitkSpacing>
            )}
          </UitkCardContentSection>
          <UitkCardContentSection border="top">
            <UitkLayoutFlex justifyContent="space-between">
              <>
                <UitkText size={200}>{offer.dates}</UitkText>
                {packageOffer?.price.value && (
                  <div>
                    <UitkSpacing padding={{ inlineend: "one" }}>
                      <UitkText size={100} inline align="right" className="price-prefix">
                        {packageOffer.price.prefixText}
                      </UitkText>
                    </UitkSpacing>
                    <UitkText size={600} weight="medium" theme="emphasis" align="right" inline>
                      {packageOffer.price.value}
                    </UitkText>
                    <UitkText size={100} align="right">
                      {packageOffer.price.qualifierText}
                    </UitkText>
                  </div>
                )}
                {packageOffer?.price.checkPriceText && (
                  <div>
                    <UitkLink align="right">
                      <UitkText weight="bold">
                        <a href={packageOffer?.actionLink.href} className="price-prefix">
                          {packageOffer?.price.checkPriceText}
                        </a>
                      </UitkText>
                    </UitkLink>
                  </div>
                )}
              </>
            </UitkLayoutFlex>
          </UitkCardContentSection>
        </div>
        <UitkCardLink>
          <TrackedLink
            moduleName={moduleName}
            action={Action.CLICK}
            href={packageOffer?.actionLink.href}
            rfrr={`card-${campaignId}-${cardIndex}`}
          >
            {packageOffer?.actionLink.text}
          </TrackedLink>
        </UitkCardLink>
      </UitkCard>
    );
  }
);

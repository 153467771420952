import * as React from "react";
import { observer } from "mobx-react";
import { RecommendedType } from "__generated__/typedefs";
import { FullBleedCard } from "../../Destination/FullBleedCard/FullBleedCard";
import { ImageTopCard } from "../../Destination/ImageTopCard/ImageTopCard";
import { VRCard } from "../../Destination/VRCard/VRCard";
import { HotelInventoryCard } from "../../Inventory/HotelInventoryCard/HotelInventoryCard";
import { FlightInventoryCard } from "../../Inventory/FlightInventoryCard/FlightInventoryCard";
import { PackageInventoryCard } from "../../Inventory/PackageInventoryCard/PackageInventoryCard";
import { ActivityInventoryCard } from "../../Inventory/ActivityInventoryCard/ActivityInventoryCard";
import { OfferItem } from "../../types";

export interface MerchCampaignCardProps {
  offer: OfferItem;
  cardIndex: number;
  campaignId: string;
  productTypes: string[];
  recommendedType?: RecommendedType | null;
  destinationView: string;
  displayDate: boolean;
  displayDescription: boolean;
  displayPrice: boolean;
  displayPropertyCount?: boolean;
  border: boolean;
  moduleName: string;
}

export enum CarouselModuleViewTypes {
  VR = "carousel-vr",
  REDESIGN = "carousel-redesign",
  DEFAULT = "carousel",
}

export const destinationOfferItem = (productType: string, offer: any) => {
  switch (productType) {
    case "Hotel":
      return offer.lodgingOffer;
    case "Flight":
      return offer.flightOffer;
    case "Package":
      return offer.packageOffer;
    default:
      return null;
  }
};

// decides the card to be displayed in merch campaigns and deals modules.
export const MerchCampaignCard = observer((props: MerchCampaignCardProps) => {
  const { destinationView, recommendedType, productTypes, moduleName, border, ...viewProps } = props;
  const productType = productTypes[0];

  const destinationCard: any = {
    fullBleed: <FullBleedCard moduleName={moduleName} productType={productType} border={border} {...viewProps} />,
    imageTop: <ImageTopCard moduleName={moduleName} productType={productType} border={border} {...viewProps} />,
    vr: <VRCard moduleName={moduleName} productType={productType} {...viewProps} />,
  };

  const inventoryType: any = {
    Hotel: <HotelInventoryCard moduleName={moduleName} border={border} {...viewProps} />,
    Flight: <FlightInventoryCard moduleName={moduleName} border={border} {...viewProps} />,
    Package: <PackageInventoryCard moduleName={moduleName} border={border} {...viewProps} />,
    LX: <ActivityInventoryCard moduleName={moduleName} border={border} {...viewProps} />,
  };

  if (recommendedType === "DESTINATIONS") {
    return destinationCard[destinationView || "fullBleed"];
  }

  return inventoryType[productTypes[0]];
});
